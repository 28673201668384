import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    authData: {},
    email: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        AuthData: (state, action) => {
            state.authData = action.payload
        },
        emailStore: (state, action) => {
            state.email = action.payload
        },
        logoutAction: (state, action) => {
            console.log("logututu");
            return initialState
        }
    }
})

export const { AuthData, logoutAction, emailStore } = authSlice.actions
export default authSlice.reducer