import { Suspense, lazy } from "react";
import './App.css'
import logo from './assets/logo.png'
import { Image } from "antd";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
const AppRouter = lazy(() => import("./routes"));
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div className="splash_screen">
          <Image preview={false} src={logo} alt="logo_image" height={300} className="logo_image" />
        </div>}>
          <AppRouter />
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export default App