import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    siteListing:[]
}

export const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        siteDataStore: (state, action) => {
            state.siteListing = action.payload
        },
        removeSiteAction: (state, action) => {
            return initialState
        }
    }
})

export const { siteDataStore, removeSiteAction} = siteSlice.actions
export default siteSlice.reducer