import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducer/AuthReducer";
import TechnicianReducer from "./reducer/TechnicianReducer";
import SiteReducer from "./reducer/SiteReducer";

const persistConfig = {
    key: 'root', // The key to use in localStorage
    storage, // Use the storage engine (e.g., localStorage)
    whitelist: ['auth','technician','site']
};


const reducers = combineReducers({
    auth: AuthReducer,
    site:SiteReducer,
    technician:TechnicianReducer
})
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
})
const persistor = persistStore(store);

export { store, persistor };