import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    technicianList:[]
}

export const techniSlice = createSlice({
    name: 'technician',
    initialState,
    reducers: {
        technicianDataStore: (state, action) => {
            state.technicianList = action.payload
        },
        removeTechnicianAction: (state, action) => {
            return initialState
        }
    }
})

export const { technicianDataStore, removeTechnicianAction} = techniSlice.actions
export default techniSlice.reducer